<template>
  <div class="box-body">
    <transition name="fade" v-if="show_comment_form !== false && !isDeleted">
      <form @keydown="form.onKeydown($event)" @submit.prevent="addComment" v-if="onProgress === false">
        <div class="row">
          <div class="col-12" v-bind:class="{ 'has-error': form.errors.has('comment') }">
            <div class="form-group">
              <textarea class="form-control"
                        maxlength="3000"
                        id="comment"
                        rows="3"
                        placeholder="Комментарий"
                        v-model="form.comment">
              </textarea>
              <HasError :form="form" field="comment"/>
            </div>

            <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

            <Button :disabled="!validateData || form.busy"
                    :form="form"
                    :loading="form.busy"
                    class="btn btn-lg btn-success pull-right"
                    style="min-width: 80px;">
              <i class="fa fa-plus-square"></i>
            </Button>
          </div>
        </div>
      </form>
    </transition>

    <ul class="timeline timeline-inverse mt-1" v-for="comment in task_comments">
      <li class="time-label">
        <span :class="comment.is_client ? 'bg-light-blue-active' : 'bg-blue'">
          {{ comment.created_date }}
        </span>
      </li>
      <li>
        <div class="timeline-item">
          <div class="timeline-body">
            <p class="mb-3">
              {{ comment.message }}
            </p>

            <button class="btn btn-danger btn-sm"
                    type="button"
                    title="Удалить"
                    v-on:click="removeComment(comment.id)">
              <i class="fa fa-trash"></i>
            </button>

            <p class="font-small mt-2 pull-right">
              Автор:
              <span v-if="!comment.is_client && comment.creator">
                <strong>{{ comment.creator.name }}</strong> (ID {{ comment.creator.id }})
              </span>
              <span v-else>
                <strong>Клиент</strong>
              </span>
            </p>

            <br/>
          </div>
        </div>
      </li>
      <li>
        <i class="fa fa-clock-o bg-gray"></i>
      </li>
    </ul>
    <div class="panel panel-default" v-if="task_comments.length === 0">
      <div class="panel-body text-center text-muted mt-5 mb-5">
        Нет элементов для отображения
      </div>
    </div>
  </div>
</template>

<script>
import Form from 'vform';

Form.axios = API.apiClient;

export default {
  name: 'task-comments',

  components: {
    Button, HasError, AlertError, AlertErrors,
  },

  props: {
    taskId: {
      type: [String, Number],
      required: true,
    },
    isDeleted: {
      type: Boolean,
      required: true,
    },
    show_comment_form: {
      type: [Boolean, null],
      default: true,
    },
  },

  data: () => ({
    form: new Form({
      comment: null,
    }),

    task_comments: [],

    onProgress: true,
  }),

  async created() {
    try {
      await this.getTaskComments();
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {
    validateData: function () {
      return this.form.comment;
    },
  },

  methods: {
    async getTaskComments() {
      try {
        const task_comments_response = await API.apiClient.get('/task/' + this.taskId + '/comments');

        this.task_comments = task_comments_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgress = false;
    },

    async addComment() {
      await this.form.post('/task/' + this.taskId + '/comments/add').then(() => {
        showSuccess();
        this.getTaskComments();
      }).catch((error) => {
        errorHandler(error);
      });
      this.form.comment = null;
    },

    removeComment(commentId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/task/' + this.taskId + '/comments', {
          data: {
            comment_id: commentId,
          }
        }).then(() => {
          showSuccess();
          this.getTaskComments();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
